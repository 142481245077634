import React, {useEffect} from "react";
import Navbar from "../../components/Navbar";
import Helmet from "react-helmet";
import TitleSection from "../../components/TitleSection";
import "./website-koppeling.scss";
import Footer from "../../components/Footer";

const WebsiteKoppelingAanmelding = () => {

    useEffect(() => {
        const qs = window.location.search;
        const url = process.env.GATSBY_WEBSITEKOPPELING_URL_INTAKE;
        const origin = process.env.GATSBY_WEBSITEKOPPELING_ORIGIN;
        document.getElementById('careweb-connector').src = `${url}/${qs}`;
        window.addEventListener("message", (event) => {
            if (event.origin !== origin) return;
            const height = event.data?.height;
            if (height) {
                document.getElementById('careweb-connector').height = height + "px";
            }
        }, false);
    }, [])

    return (
        <>
            <Helmet>
                <body className="page-website-integration" />
            </Helmet>


            <Navbar/>

            <main>

                <article className="bg-circle">
                    <TitleSection title="Website Koppeling" subtitle="Voorbeeld uitnodiging Intake"/>

                    <div className="content">


                        <div className="cols">

                            <div className="col-sm-100">

                                <h2>Intake formulier</h2>

                                <section className="text-based">
                                    <p>
                                        Gelieve deze vragen minimaal 24 uur voor het intake gesprek in te vullen.
                                    </p>
                                    <p>
                                        Problemen met invullen en/of versturen van het formulier?<br/>
                                        U kunt ons bereiken per telefoon via 06 1422 0799 op werkdagen tussen 08.30-12.00 en 14.30-16.30.
                                    </p>

                                </section>

                                <section>
                                    <iframe id="careweb-connector"></iframe>
                                </section>
                            </div>
                        </div>
                    </div>
                </article>

            </main>

            <Footer />
        </>

    )
}

export default WebsiteKoppelingAanmelding;
